<template>
  <div>
    <div class="hs-box-shadow">
      <div class="d-flex position-relative bg-white p-3 ">
        <div class="d-flex flex-column col-12 col-sm-8 px-0 ">
          <span class="font-weight-bold">{{ $t('sign_up.questionary.complete.title') }}</span>
          <span class="mt-2 hs-text-14">
            {{ $t('sign_up.questionary.complete.subtitle') }}
          </span>
        </div>
        <div class="position-relative d-flex justify-content-center col-xs-12 col-sm-4 px-0 min-height-box">
          <img
            width="160"
            class="d-none d-sm-block position-absolute"
            :src="headerIllustration"
            alt="questionary-finished"
          />
        </div>
      </div>
      <div class="tlw-bg-white d-flex d-sm-none justify-content-center align-items-center w-100 py-4">
        <img width="200" :src="headerIllustration" alt="questionary-finished" />
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-2 mb-6 mb-sm-0 btn-mobile-wrapper">
      <a
        role="button"
        class="hs-questionary__btn-back pointer btn py-3 pr-4 pl-0 text-purple"
        @click="() => $emit('back')"
      >
        <hs-icon icon="arrow-left" class="mr-2"></hs-icon>
        {{ $t('sign_up.questionary.back') }}
      </a>

      <m-button
        data-testid="finishquest"
        variant="primary"
        class="col-xs-12 col-sm-auto btn-prosseguir"
        @click="() => $emit('finished')"
        :label="$t('sign_up.questionary.finish')"
      />
    </div>
  </div>
</template>

<script>
import MButton from '@/shared/components/MButton.vue';

export default {
  components: {
    MButton,
  },
  data() {
    return {
      headerIllustration: require('@/assets/images/sign_up/brain-celebration.svg'),
    };
  },
};
</script>
<style>
.min-height-box {
  min-height: 11em;
}
@media screen and (max-width: 580px) {
  .btn-mobile-wrapper {
    display: flex;
    flex-column: column !important;
    flex-wrap: wrap-reverse;
  }
  .btn-prosseguir {
    margin: 24px 0;
  }
}
</style>
