<template>
  <section
    class="hs-questionary d-flex flex-column mt-3"
    :class="{ 'align-items-center': boxModel }"
    :style="{
      marginBottom: isLoading ? 'none' : 'auto',
    }"
  >
    <hs-loading v-if="isLoading" />
    <div :class="{ 'tlw-bg-white py-4 px-4 mt-5 hs-step-questionary__wrapper': boxModel }">
      <QuestionaryHeader
        class="d-none d-sm-block delay-200"
        :class="{ 'tlw-opacity-0': actual > 1 }"
        v-if="!isLoading && actual < 2"
      />
      <QuestionaryMobileInitial
        v-if="!isLoading"
        v-model="initialVisible"
        :actual="actual"
        :illustration="illustration"
      />
      <QuestionaryBody
        v-if="!initialVisible && !isLoading"
        data-testid="questionary-body"
        :steps="steps"
        :actual="actual"
        :questions="questions"
        :singular="singularLabels"
        class="d-flex d-sm-none flex-column justify-content-end"
        @selected="onOptionSelected"
        @step="onStepChange"
      />
      <QuestionaryBody
        v-if="!isLoading"
        :steps="steps"
        :actual="actual"
        :questions="questions"
        :singular="singularLabels"
        class="d-none d-sm-flex flex-column justify-content-end"
        @selected="onOptionSelected"
        @step="onStepChange"
      />
    </div>
  </section>
</template>

<script>
import debug from 'debug';
import { mapMutations, mapActions, mapState } from 'vuex';
import { hsLoading } from '@/components';
import QuestionaryHeader from './QuestionaryHeader.vue';
import QuestionaryMobileInitial from './QuestionaryMobileInitial.vue';
import QuestionaryBody from './QuestionaryBody.vue';
import { analyticsService, hubspotService } from '@/services';
import questionaryService from '@/services/questionaryNew';
import ToastHelper from '@/shared/helpers/toast';
import inviteService from '@/sparkaffiliates/services/invite';
import Features from '@/types/features';

const logging = debug('hs:sign-up-questionairy');

export default {
  name: 'StepQuestionaryNew',
  components: {
    hsLoading,
    QuestionaryHeader,
    QuestionaryMobileInitial,
    QuestionaryBody,
  },
  props: {
    product: {
      type: String,
      required: true,
    },
    boxModel: {
      type: Boolean,
    },
    inviteCode: {
      type: String,
    },
  },
  data() {
    const questions = questionaryService.getQuestions();
    return {
      isLoading: false,
      initialVisible: true,
      formType: '',
      singularLabels: true,
      steps: questions.map((_, index) => ({ id: index + 1 })),
      actual: 1,
      questions,
    };
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
    illustration() {
      return this.product === 'sparkfunnels'
        ? require('@/assets/images/funnel/funnels_illustration.svg')
        : require('@/assets/images/sign_up/illustration-signup-video.svg');
    },
  },
  methods: {
    ...mapMutations('school', ['addFeature']),
    ...mapActions('school', ['updateSchool', 'enableFeature']),
    onOptionSelected({ index: questionIndex, option, isMultiselect, showNextButton }) {
      if (!this.questions[questionIndex].multiselect) option.selected = true;
      this.questions[questionIndex].selectedOption = option.value;
      let actions = {
        0: 'runFirstQuestion',
        1: 'runSecondQuestion',
        2: 'runThirdQuestion',
      };
      const action = actions[questionIndex] || 'runOtherQuestion';
      this[action]({ index: questionIndex, option, isMultiselect, showNextButton });
    },
    async onFinish() {
      try {
        this.isLoading = true;

        const questions = questionaryService.normalizeQuestions(this.questions);

        const planReferral = window.sessionStorage.getItem('plan-referral') || null;

        await this.updateSchool({
          id: this.selectedSchool.id,
          extra_settings: {
            signup_form: questions,
            questionary_filled: Date.now(),
            questionary_skipped: false,
            show_top_bar: ['producer', 'mktProfessional'].includes(questions.customerKind),
          },
        });
        hubspotService.sendNewQuestionnaire(
          questions,
          this.selectedSchool.id,
          this.selectedSchool.name,
          this.selectedSchool.email,
          planReferral
        );
        analyticsService.identify(undefined, questions);
        analyticsService.track({
          event: 'Questionary Filled',
          props: {
            schoolId: this.selectedSchool.id,
            schoolName: this.selectedSchool.name,
            ...questions,
          },
        });

        window.sessionStorage.removeItem('plan-referral');

        let redirectTo = { name: 'HomePage' };

        const invite = window.sessionStorage.getItem('invite-code');

        if (invite) {
          const { inviteCode, product } = JSON.parse(invite);

          await inviteService.acceptInvite(
            inviteCode,
            this.selectedSchool.account_type,
            this.selectedSchool.id,
            this.selectedSchool.email,
            this.selectedSchool.name
          );

          let enableFeature;

          if (product === 'sparkaffiliates') {
            enableFeature = Features.SPARK_AFFILIATES;
            redirectTo = { ...this.$route, name: 'MyAffiliations' };
          } else if (product === 'coproduction') {
            enableFeature = Features.COPRODUCTOR;
            redirectTo = { ...this.$route, name: 'ProductsList', params: { goToCoprodutions: 'true' } };
          }

          enableFeature && this.addFeature(enableFeature);

          window.sessionStorage.removeItem('invite-code');
        }

        this.$router.push(redirectTo);
      } catch (err) {
        const $err = err;
        logging('failed sign-up', $err);
        ToastHelper.dangerMessage(this.$t(`sign_up.questionary.toast.error`));
        this.isLoading = false;
      }
    },
    onStepChange(step, direction = 'next') {
      if (this.actual === 6 && this.formType === 'mktProfessional' && direction === 'back') {
        this.actual = 1;
        return;
      }
      if (this.actual === 2 && this.formType === 'mktProfessional' && direction === 'next') {
        this.actual = 7;
        return;
      }
      if (step === this.questions.length + 1) {
        this.onFinish();
        return;
      }
      this.actual = step;
    },
    runFirstQuestion(opt) {
      this.formType = opt.option.value;
      const step = {
        producer: 2,
        affiliate: 6,
        mktProfessional: 2,
      };
      this.actual = step[this.formType];
    },
    runSecondQuestion(opt) {
      this.singularLabels = this.questions[1].options.some(option => option.value == 'alone' && option.selected);
      const step = {
        producer: 3,
        affiliate: 6,
        mktProfessional: 6,
      };
      this.actual = step[this.formType];
    },
    runThirdQuestion(data) {
      this.actual = data.option.some(item => item.value === 'ebook' || item.value === 'online_course') ? 4 : 6;
    },
    runOtherQuestion(opt) {
      if (!opt.isMultiselect) {
        if (opt.option.selected) {
          this.questions[opt.index].options.forEach(curr => {
            if (curr.selected && curr.id !== opt.option.id) {
              curr.selected = false;
            }
          });
        }
      }
      let to = opt.index + 2;
      if (opt.index === 6 && this.formType === 'affiliate') to = 9;
      this.actual = to;
    },
  },
};
</script>

<style lang="scss" scoped></style>
