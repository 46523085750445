<template>
  <div>
    <div id="QuestionWrapper" class="w-100 tlw-bg-white" :class="{ 'hs-box-shadow': boxMode }">
      <div class="d-flex flex-column" :class="{ 'p-3': boxMode }">
        <span class="font-weight-bold" v-html="getText(question.key)" />

        <span class="mt-2 hs-text-14">
          {{ question.multiselect ? $t(question.desc || 'sign_up.questionary.multiselect') : '' }}
        </span>
      </div>

      <div class="d-flex flex-column" :class="{ 'px-3 py-3': boxMode }">
        <div
          v-for="(option, index) in actualStep && question.options"
          :key="option.value"
          class="d-flex w-100 align-items-center form-control my-2 h-auto hs-questions-wrapper_item"
          :class="(option.selected && 'bd-purple') || ''"
        >
          <hs-checkbox
            v-if="question.multiselect"
            v-model="option.selected"
            @input="saveOptionSelected(actualStep - 1, option)"
            class="w-100 flex-grow"
          >
            <span
              v-if="option.type !== 'input' || !option.selected"
              :class="option.selected"
              v-html="getText(option.key)"
            />
            <div
              class="col-xs-12 col-sm-6 px-0"
              v-if="option.selected && (option.type === 'input' || option.type === 'number')"
            >
              <hs-input
                @blur="$v.question.options.$each[index].inputValue.$touch()"
                :state="!$v.question.options.$each[index].inputValue.$error ? null : false"
                :placeholder="option.inputPlaceholder"
                v-model="option.inputValue"
                :type="option.type"
                autofocus
              >
                <template slot="feedback" v-if="$v.question.options.$each[index].inputValue.$error">
                  <hs-form-invalid-feedback :state="false">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </div>
          </hs-checkbox>

          <div class="w-100 flex-grow tlw-bg-red" v-else>
            <hs-radio
              @change="saveOptionSelected(actualStep - 1, option)"
              v-model="question.selectedOption"
              :value="option.value"
              class="hs-radio-custom"
            >
              <span
                v-if="option.type !== 'input' || !option.selected"
                :class="option.selected && 'font-weight-bold'"
                v-html="getText(option.key)"
              />
              <div
                class="col-xs-12 col-sm-6 px-0"
                v-if="option.selected && (option.type === 'input' || option.type === 'number')"
              >
                <hs-input
                  @blur="$v.question.options.$each[index].inputValue.$touch()"
                  :state="!$v.question.options.$each[index].inputValue.$error ? null : false"
                  :placeholder="option.inputPlaceholder"
                  v-model="option.inputValue"
                  :type="option.type"
                  autofocus
                >
                  <template slot="feedback" v-if="$v.question.options.$each[index].inputValue.$error">
                    <hs-form-invalid-feedback :state="false">
                      {{ $t('validations.required') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </div>
            </hs-radio>
          </div>
        </div>
        <div v-if="question.subquestions">
          <div v-for="subquestion in question.subquestions" :key="subquestion.key" class="question-with-subquestions">
            <label>{{ subquestion.label }}</label>
            <hs-input
              :placeholder="subquestion.placeholder"
              v-model="subquestion.value"
              :type="'text'"
              :autofocus="subquestion.autofocus"
            >
            </hs-input>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-2 mb-6 mb-sm-0 btn-mobile-wrapper">
      <a
        role="button"
        v-if="actualStep > 1"
        class="hs-questionary__btn-back pointer btn py-3 pr-4 pl-0 text-purple"
        @click="back()"
      >
        <hs-icon icon="arrow-left" class="mr-2"></hs-icon>
        {{ $t('sign_up.questionary.back') }}
      </a>

      <m-button
        variant="primary"
        class="col-xs-12 col-sm-auto btn-prosseguir"
        @click="onGoNext"
        :label="$t('sign_up.questionary.next')"
        :disabled="!validate"
      />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import MButton from '@/shared/components/MButton.vue';

export default {
  props: {
    question: Object,
    validate: Boolean,
    actualStep: Number,
    multiselect: {
      type: Boolean,
      default: false,
    },
    singular: {
      type: Boolean,
      default: true,
    },
    boxMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dados: {},
      SelectedOptions: null,
    };
  },
  components: { MButton },
  validations: {
    question: {
      options: {
        $each: {
          inputValue: {
            required,
          },
        },
      },
    },
  },
  methods: {
    back() {
      this.$emit('back');
    },
    getText(key) {
      const text = this.$t(key);
      if (!text.s) return text;
      return this.singular ? text.s : text.p;
    },
    saveOptionSelected(actualStep, option) {
      if (this.multiselect) {
        this.selectedOptions = this.question.options.filter(option => option.selected);
        this.dados = { index: actualStep, option: this.question.options.filter(option => option.selected) };
      } else {
        this.dados = { index: actualStep, option };
      }
    },
    onGoNext() {
      if (this.dados.option) {
        this.$emit('selected', this.dados);
      } else {
        this.$emit('nextStep');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ #QuestionWrapper {
  .hs-radio-custom {
    display: flex;
    align-items: center;
  }
  .custom-control-label {
    width: 100%;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
  .custom-control-input:hover ~ .custom-control-label:before {
    border-color: $purple-dark !important;
  }
  .custom-control-input:not(:focus) ~ .custom-control-label:before {
    border: 1px solid #adb5bd;
  }
  .custom-control-input:active ~ .custom-control-label:before {
    background-color: transparent;
  }
}

.hs-questions-wrapper_item:hover {
  border-color: $purple-dark;
}
.btn-wrapper {
  padding-top: 1em;
}
.btn-prosseguir {
  margin-left: auto;
  @media screen and (max-width: 580px) {
    margin: 24px 0;
  }
}
@media screen and (max-width: 580px) {
  .btn-mobile-wrapper {
    display: flex;
    flex-direction: column !important;
    flex-wrap: wrap-reverse;
  }
}

.question-with-subquestions {
  margin-bottom: 2em;
}
.text-purple {
  color: $purple-dark;
}
.bd-purple {
  border-color: $purple-dark;
}
</style>
