const q1Options = [
  {
    key: 'sign_up.questionary.question1.opt1',
    value: 'producer',
  },
  {
    key: 'sign_up.questionary.question1.opt2',
    value: 'affiliate',
  },
  {
    key: 'sign_up.questionary.question1.opt3',
    value: 'mktProfessional',
  },
];

const q2Options = [
  {
    key: 'sign_up.questionary.question2.opt1',
    value: 'alone',
  },
  {
    key: 'sign_up.questionary.question2.opt2',
    value: 'withTeam',
  },
  {
    key: 'sign_up.questionary.question2.opt3',
    value: 'withSpecialist',
  },
];

const q3Options = [
  {
    key: 'sign_up.questionary.question3.opt1',
    value: 'consultancyebook',
  },
  {
    key: 'sign_up.questionary.question3.opt2',
    value: 'ebook',
  },
  {
    key: 'sign_up.questionary.question3.opt3',
    value: 'online_course',
  },
  {
    key: 'sign_up.questionary.question3.opt4',
    value: 'individual_mentoring',
  },
  {
    key: 'sign_up.questionary.question3.opt5',
    value: 'group_mentoring',
  },
  {
    key: 'sign_up.questionary.question3.opt6',
    value: 'webinar',
  },
  {
    key: 'sign_up.questionary.question3.opt7',
    value: 'none_sale',
  },
  {
    key: 'sign_up.questionary.question3.opt8',
    value: 'outhers',
  },
];

const q4Options = [
  {
    key: 'sign_up.questionary.question4.opt1',
    value: 'hotmart',
  },
  {
    key: 'sign_up.questionary.question4.opt2',
    value: 'eduzz',
  },
  {
    key: 'sign_up.questionary.question4.opt3',
    value: 'pagarme',
  },
  {
    key: 'sign_up.questionary.question4.opt4',
    value: 'kiwify',
  },
  {
    key: 'sign_up.questionary.question4.opt5',
    value: 'monetizze',
  },
  {
    key: 'sign_up.questionary.question4.opt6',
    value: 'green',
  },
  {
    key: 'sign_up.questionary.question4.opt7',
    value: 'outhers',
  },
];

const q5Options = [
  {
    key: 'sign_up.questionary.question5.opt1',
    value: 'recurrence',
  },
  {
    key: 'sign_up.questionary.question5.opt2',
    value: 'launch_formula',
  },
  {
    key: 'sign_up.questionary.question5.opt3',
    value: 'perpetual',
  },
  {
    key: 'sign_up.questionary.question5.opt4',
    value: 'product_portfolio',
  },
  {
    key: 'sign_up.questionary.question5.opt5',
    value: 'community',
  },
  {
    key: 'sign_up.questionary.question5.opt6',
    value: 'affiliates',
  },
  {
    key: 'sign_up.questionary.question5.opt7',
    value: 'dont_know_yet',
  },
  {
    key: 'sign_up.questionary.question5.opt8',
    value: 'none_strategy',
  },
];

const getQuestions = () => [
  {
    value: 'customerKind',
    selectedOption: '',
    key: 'sign_up.questionary.question1.title',
    options: buildStandartOptions(q1Options),
  },
  {
    value: 'teamSize',
    key: 'sign_up.questionary.question2.title',
    options: buildStandartOptions(q2Options),
  },
  {
    value: 'saleExperience',
    multiselect: true,
    key: 'sign_up.questionary.question3.title',
    options: buildStandartOptions(q3Options),
  },
  {
    value: 'platformUsed',
    multiselect: true,
    key: 'sign_up.questionary.question4.title',
    options: buildStandartOptions(q4Options),
  },
  {
    value: 'saleStrategy',
    multiselect: true,
    key: 'sign_up.questionary.question5.title',
    options: buildStandartOptions(q5Options),
  },
  {
    value: 'socialMedia',
    subquestions: [
      {
        property: 'instagramProfile',
        placeholder: '@seuperfil',
        label: 'Instagram',
        key: 'sign_up.questionary.question7.instagram',
        autofocus: true,
      },
      {
        property: 'youtubeProfile',
        placeholder: 'https://www.youtube.com/channel/_',
        label: 'Youtube',
        key: 'sign_up.questionary.question7.youtube',
      },
      {
        property: 'othersProfile',
        placeholder: 'Facebook, TikTok...',
        label: 'Outros',
        key: 'sign_up.questionary.question7.outros',
      },
    ],
    key: 'sign_up.questionary.question6.title',
    options: [],
  },
];

const disallowedSteps = {
  producer: [],
  affiliate: [2, 3, 4, 5],
  mktProfessional: [3, 4, 5],
};

const buildStandartOptions = (opts, types = 'text') =>
  opts.map((opt, index) => ({
    id: index + 1,
    type: types,
    key: opt.key,
    value: opt.value,
    selected: false,
  }));

const normalizeQuestions = questions => {
  const questionsObj = {};
  for (const question of questions) {
    if (question.multiselect) {
      questionsObj[question.value] = question.options
        .filter(option => option.selected)
        .map(option => {
          if (option.type === 'input' || option.type === 'number') {
            questionsObj[option.value] = option.inputValue;
            return `${option.inputValue}`;
          }
          return option.value;
        });
    } else if (question.selectedOption) {
      const selectedOpt = question.options.find(option => option.selected);
      if (selectedOpt) {
        if (selectedOpt.type === 'input' || selectedOpt.type === 'number') {
          questionsObj[question.value] = `${selectedOpt.inputValue}`;
        } else {
          questionsObj[question.value] = question.selectedOption;
        }
      }
    } else if (question.subquestions) {
      question.subquestions.forEach(subquestion => {
        questionsObj[subquestion.property] = subquestion.value;
      });
    }
  }
  return questionsObj;
};

export default {
  disallowedSteps,
  getQuestions,
  normalizeQuestions,
  buildStandartOptions,
};
