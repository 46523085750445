<template>
  <div class="tlw-w-screen tlw-h-full tlw-overflow-x-hidden tlw-flex tlw-flex-wrap xl:tlw-flex-nowrap">
    <main
      class="tlw-w-full xl:tlw-w-1/2 tlw-h-screen xl:tlw-h-full tlw-order-2 xl:tlw-order-1 tlw-flex tlw-flex-col tlw-justify-center tlw-px-20 xl:tlw-overflow-y-auto tlw-p-5"
    >
      <figure class="tlw-m-4 tlw-mt-48 xl:tlw-mt-4 tlw-mx-auto">
        <hs-logo height="25" color="#FF305C" />
      </figure>
      <b-tabs v-model="step" nav-class="d-none" nav-wrapper-class="d-none" class="tlw-mt-10">
        <b-tab v-for="tab in tabs" :key="tab.id" lazy>
          <component id="form" :is="tab.component" @proceed="step++" :product="product" :invite-code="inviteCode" />
        </b-tab>
      </b-tabs>
    </main>
    <aside
      class="tlw-relative tlw-bg-cover tlw-bg-center xl:tlw-bg-left-top tlw-w-full xl:tlw-w-auto tlw-flex-grow tlw-h-screen xl:tlw-h-full tlw-flex tlw-order-1 xl:tlw-order-2 tlw-pt-5 xl:tlw-pt-0 tlw-items-start xl:tlw-items-center"
      :style="{ 'background-image': `url(${backgroundImage})` }"
    >
      <section class="tlw-w-2full xl:tlw-w-2/5">
        <section class="tlw-pl-14 xl:tlw-pl-24 tlw-text-white">
          <h3 class="tlw-text-xs xl:tlw-text-xl tlw-font-black">{{ copies.intro.name }}</h3>
          <p class="tlw-mt-2">{{ copies.intro.description }}</p>
          <template v-if="copies.transformation">
            <h3 class="tlw-text-xs xl:tlw-text-xl tlw-font-black tlw-mt-10">
              {{ $t('sign_up.advertised.transform') }}
            </h3>
            <p class="tlw-mt-2">{{ copies.transformation.text }}</p>
          </template>
          <h3 class="tlw-text-xs xl:tlw-text-xl tlw-font-black tlw-mt-10">{{ copies.features.label }}</h3>
          <ul class="tlw-mt-2">
            <li v-for="(feature, index) in copies.features.list" :key="index">{{ feature }}</li>
          </ul>
        </section>
        <section
          class="xl:tlw-bg-white tlw-p-2 xl:tlw-p-10 tlw-pl-12 xl:tlw-pl-24 tlw-border-l-4 tlw-border-purple-500 tlw-mt-5"
        >
          <h3 class="tlw-text-xs xl:tlw-text-xl tlw-text-white xl:tlw-text-purple-500 tlw-font-extrabold">
            {{ copies.whiteFrame.title }}
          </h3>
          <p class="tlw-mt-5 tlw-text-white xl:tlw-text-gray-700" v-html="copies.whiteFrame.description" />
        </section>
        <p class="tlw-text-sm tlw-text-right tlw-text-white" v-if="copies.whiteFrame.showPlanDisclaimer">
          *{{ $t('sign_up.advertised.planDisclaimer') }}
        </p>
        <a
          href="#form"
          class="tlw-mx-10 tlw-my-2 xl:tlw-hidden tlw-bg-purple-500 tlw-text-white active:tlw-text-white tlw-py-4 tlw-px-8 tlw-inline-block"
        >
          {{ $t('sign_up.advertised.signup') }}
        </a>
      </section>
    </aside>
  </div>
</template>

<script>
import hsLogo from '@/components/Logo';
import StepInfo from './../SignUp/components/StepInfo';
import StepName from './../SignUp/components/StepName';
import StepQuestionaryNew from './../SignUp/components/StepQuestionaryNew';

import advertisingCopies from '@/data/advertisingCopies';

export default {
  components: { hsLogo },
  data() {
    return {
      step: 0,
    };
  },
  created() {
    this.startQuestionary();
  },
  computed: {
    inviteCode() {
      return this.$route.query.inviteCode;
    },
    backgroundImage() {
      return require(`@/assets/images/sign_up/signup-${this.product}.webp`);
    },
    copies() {
      return advertisingCopies[this.product];
    },
    product() {
      const product =
        ['sparkaffiliates', 'sparkmembers', 'sparkfunnels'].includes(this.$route.query.product) &&
        this.$route.query.product;
      return product || 'sparkmembers';
    },
    tabs() {
      return [
        {
          component: StepInfo,
          id: 0,
        },
        {
          component: StepName,
          id: 1,
        },
        {
          component: StepQuestionaryNew,
          id: 2,
        },
      ];
    },
  },
  methods: {
    startQuestionary() {
      /*
        Registered users that didnt finish the signup questionary yet
        are redirect to here with forceQuestionary when try to access another page.
      */
      if (this.$route.params?.forceQuestionary) this.step = 2;
    },
  },
};
</script>
<style lang="scss">
iframe#launcher {
  visibility: initial;
}
</style>
