<template>
  <div class="d-flex justify-content-center align-items-center flex-column" :class="{ 'w-100': isLoading }">
    <hs-loading v-if="isLoading" />
    <template v-else>
      <termModal id="term-modal" :kind="kindTerm" />
      <hs-form :id="`signUp_form_${product}`" @submit="submit" class="stepInfo">
        <div>
          <h5 class="auth-title" slot="title">{{ $t(`sign_up.${product}.form.title`) }}</h5>
          <p class="auth-subtitle" v-html="$t(`sign_up.${product}.form.subtitle`)"></p>
        </div>
        <hs-group :label="$t(`sign_up.${product}.form.fields.name`)" label-for="signUp_name">
          <hs-input
            id="signUp_name"
            type="text"
            :placeholder="$t(`sign_up.${product}.form.fields.placeholder_name`)"
            @blur="$v.form.name.$touch()"
            v-model="form.name"
            :state="!$v.form.name.$error ? null : false"
          >
            <template slot="feedback" v-if="$v.form.name.$error">
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.name.required">
                {{ $t(`sign_up.${product}.form.validation.required.name`) }}
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>

        <hs-group :label="$t(`sign_up.${product}.form.fields.email`)" label-for="signUp_email">
          <hs-input
            id="signUp_email"
            :placeholder="$t(`sign_up.${product}.form.fields.placeholder_email`)"
            @blur="$v.form.email.$touch()"
            v-model="form.email"
            :state="!$v.form.email.$error ? null : false"
            :disabled="disableEmailInput"
          >
            <template slot="feedback" v-if="$v.form.email.$error">
              <hs-form-invalid-feedback :state="false">
                <span>
                  {{ $t(`sign_up.${product}.form.validation.required.email`) }}
                </span>
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>

        <DocumentChoose @documentChoosen="validateDocument" />

        <hs-group :label="$t(`sign_up.${product}.form.fields.phone`)" label-for="signUp_phone">
          <VueTelInput
            id="signUp_phone"
            v-model="form.phone"
            type="tel"
            :inputOptions="vueTelInputOptions"
            :defaultCountry="'BR'"
            :preferredCountries="['BR', 'US', 'AO', 'PT', 'AR', 'IT']"
            :disabledFetchingCountry="true"
            @blur="$v.form.phone.$touch()"
            @country-changed="countryChanged"
            @input="validateNumber"
          />
          <hs-form-invalid-feedback :state="!$v.form.phone.$error && isValidPhone">
            <span v-if="!$v.form.phone.required">
              {{ $t(`sign_up.${product}.form.validation.required.phone`) }}
            </span>
            <span v-if="!isValidPhone && $v.form.phone.required">
              {{ feedbackInvalid }}
            </span>
          </hs-form-invalid-feedback>
        </hs-group>

        <hs-group :label="$t(`sign_up.${product}.form.fields.password`)" label-for="signUp_password">
          <div class="d-flex position-relative flex-column input-password-container">
            <hs-input
              id="signUp_password"
              :type="passwordInputType"
              :text="
                !$v.form.password.$error ? $t(`sign_up.${product}.form.fields.password_text`, { minLength: 6 }) : ''
              "
              :placeholder="$t(`sign_up.${product}.form.fields.placeholder_password`)"
              @blur="$v.form.password.$touch()"
              v-model="form.password"
              :state="!$v.form.password.$error ? null : false"
            >
              <template slot="feedback" v-if="$v.form.password.$error">
                <hs-form-invalid-feedback :state="false">
                  <span v-if="!$v.form.password.required">
                    {{ $t(`sign_up.${product}.form.validation.required.password`) }}
                  </span>
                  <span v-if="!$v.form.password.minLength">
                    {{ $t(`sign_up.${product}.form.validation.password.minLength`, { minLength: 6 }) }}
                  </span>
                </hs-form-invalid-feedback>
              </template>
            </hs-input>
            <hs-button
              class="position-absolute show-hide-password"
              :class="{ 'fix-margin-button': $v.form.password.$error }"
              @click="showHidePassword"
              variant="outline-light"
              >{{ $t(`sign_up.${product}.form.fields.show_password`) }}</hs-button
            >
          </div>
        </hs-group>
        <hs-checkbox id="terms-of-use-consense" v-model="form.checkUseTerms" @blur="$v.form.checkUseTerms.$touch()">
          <span
            class="terms-of-use-consense font-weight-normal"
            v-html="`${$t(`sign_up.${product}.form.fields.terms_of_use_consense.label`)}`"
          ></span>
        </hs-checkbox>

        <div class="check-use-terms-error w-100" v-if="$v.form.checkUseTerms.$error">
          {{ $t(`sign_up.${product}.form.validation.required.use_terms`) }}
        </div>

        <Captcha ref="invisibleCaptcha" @verify="onVerify($event)" @error="onError" />

        <hs-button class="auth-form-button" size="lg" variant="purple" block type="submit" :disabled="shouldDisableBtn">
          <b-spinner variant="gray" v-if="loadingBtn" small />
          {{ $t(`sign_up.${product}.form.buttons.create`) }}
        </hs-button>
      </hs-form>

      <div class="auth-footer-p d-flex justify-content-center flex-wrap">
        <span v-html="`${$t(`sign_up.${product}.have_an_account`)}&nbsp;`"></span>
        <router-link :to="{ name: 'Login' }" class="router-link">
          <u>{{ $t(`sign_up.${product}.sign_in`) }}</u>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { hsForm, hsLoading } from '@/components';
import DocumentChoose from '@/components/DocumentChoose.vue';
import termModal from '@/components/TermModal';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Confirm from '@/shared/mixins/Confirm';
import Captcha from '@/components/Captcha/index.vue';
import ToasterHelper from '@/shared/helpers/toast';

export default {
  props: {
    product: {
      type: String,
      required: true,
    },
  },
  mixins: [Confirm],
  components: {
    hsLoading,
    VueTelInput,
    hsForm,
    termModal,
    Captcha,
    DocumentChoose,
  },
  computed: {
    ...mapState('school', {
      schools: state => state.items,
      selectedSchool: state => state.selectedSchool,
    }),
    ...mapState('auth', ['loggedUser']),
    ...mapGetters('auth', {
      userName: 'name',
    }),
    feedbackInvalid() {
      return this.ddi === '55'
        ? this.$t(`sign_up.${this.product}.form.fields.phone_text`)
        : this.$t(`sign_up.sparkmembers.form.fields.phone_text_international`);
    },
    shouldDisableBtn() {
      return this.$v.form.$invalid || this.loadingBtn || !this.isValidPhone || !this.validDocument;
    },
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        password: null,
        document_number: null,
        document_type: null,
        phone: '',
        checkUseTerms: false,
      },
      disableEmailInput: false,
      validDocument: false,
      isLoading: false,
      isValidPhone: true,
      loadingBtn: false,
      kindTerm: '',
      passwordInputType: 'password',
      vueTelInputOptions: {
        placeholder: this.$t(`sign_up.${this.product}.form.fields.placeholder_phone`),
      },
      ddi: 'BR',
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      name: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      phone: {
        required,
      },
      checkUseTerms: {
        checkIfTrue: value => value === true,
      },
      document_number: {
        required,
      },
    },
  },
  mounted() {
    this.setQueryParamToEmail();

    const accountTermButton = document.getElementById('account_terms_herospark');
    const privacyPolicyButton = document.getElementById('privacy_policy_herospark');
    if (accountTermButton && privacyPolicyButton) {
      accountTermButton.addEventListener('click', () => this.openUseTerms('account_terms'));
      privacyPolicyButton.addEventListener('click', () => this.openUseTerms('privacy_policy'));
    }
  },
  methods: {
    ...mapActions('school', ['getSchools', 'enableFeature']),
    ...mapMutations('funnel', ['setOpenCreateFunnelModal']),
    ...mapMutations('school', ['setNewSchool']),

    setQueryParamToEmail() {
      const emailQueryParam = this.$route.query.email;

      if (emailQueryParam) {
        this.form.email = emailQueryParam;
        this.disableEmailInput = true;
      }
    },

    submit() {
      this.loadingBtn = true;
      this.$refs.invisibleCaptcha.execute();
    },

    validateDocument({ is_valid, document, document_type }) {
      this.form.document_number = document;
      this.form.document_type = document_type;
      this.validDocument = is_valid;
    },

    validateNumber(phoneNumber, phoneObject) {
      this.isValidPhone = phoneObject.valid;
    },

    async onVerify(captcha_token) {
      try {
        this.$v.$touch();

        if (this.$v.form.$invalid) return;

        const schools = await this.getSchools({ email: this.form.email, captcha_token });

        if (schools?.length) {
          const confirmBoxOptions = {
            okTitle: this.$t(`sign_up.${this.product}.login_or_signup_modal.ok_button`),
            cancelTitle: this.$t(`sign_up.${this.product}.login_or_signup_modal.cancel_button`),
            contentTitle: this.$t(`sign_up.${this.product}.login_or_signup_modal.content_title`),
            contentDescription: this.$t(`sign_up.${this.product}.login_or_signup_modal.content_description`),
          };

          const confirmResponse = await this.showConfirmBox(confirmBoxOptions);

          if (confirmResponse === null) return;

          if (confirmResponse) {
            this.$router.push({ name: 'Login' });
            return;
          }
        }

        this.register();
      } catch (error) {
        console.log('StepInfo_onVerify', error); // eslint-disable-line no-console
        ToasterHelper.dangerMessage('Erro ao buscar escolas. Tente novamente mais tarde.');
      } finally {
        this.isLoading = false;
        this.loadingBtn = false;
      }
    },

    openUseTerms(kind) {
      this.kindTerm = kind;
      this.$bvModal.show('term-modal');
    },

    async register() {
      this.isLoading = true;
      const phone = this.form.phone.replace(/[\s\-/\-/{()}]+/g, '');

      this.setNewSchool({ ...this.form, phone, product: this.product, ddi: this.ddi });
      this.$emit('proceed');

      this.resetForm();
      this.isLoading = false;
      this.loadingBtn = false;
    },

    resetForm() {
      const { form } = this.$options.data.call(this);
      this.form = form;
      this.$v.$reset();
    },

    showHidePassword() {
      this.passwordInputType = this.passwordInputType === 'text' ? 'password' : 'text';
    },

    onError() {
      this.loadingBtn = false;
    },
    countryChanged(country) {
      this.ddi = country.dialCode;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .hs-multiselect {
  .multiselect__option {
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 2px;
  }

  .multiselect__content {
    padding: 8px;
  }

  .multiselect__option--selected {
    color: #262626;
    background: #fff;
  }
  .multiselect__option--highlight {
    color: #262626;
    background-color: #e3e3e3;
  }
}
.terms-of-use-consense {
  font-size: 14px;
  line-height: 150%;
  color: $grey-32;
  /deep/ a {
    color: $purple-dark;
    font-weight: bold;
  }
}
.input-password-container {
  .show-hide-password {
    right: 0;
    height: 38px;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: -0.166667px;
    color: $grey;
    &:hover {
      background-color: transparent;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .fix-margin-button {
    margin-right: 33px;
  }
}
.check-use-terms-error {
  font-size: 62.5%;
  font-weight: 700;
  color: $cherry-dark;
}

.auth-form-button {
  border-radius: 4px;
}

/deep/ .stepInfo {
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
  .custom-control-input:hover ~ .custom-control-label:before {
    border-color: $purple-dark !important;
  }
  .custom-control-input:not(:focus) ~ .custom-control-label:before {
    border: 1px solid #adb5bd;
  }
  .custom-control-input:active ~ .custom-control-label:before {
    background-color: transparent;
  }
  .custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: $purple-dark;
    background-color: $purple-dark;
  }
}
</style>
