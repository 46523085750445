export default {
  sparkaffiliates: {
    intro: {
      name: 'Você, futuro afiliado',
      description: `
        Utilize nossas ferramentas para gerar leads de graça e faturar até 5x mais
        rápido que qualquer outra plataforma digital.
      `,
    },
    features: {
      label: 'Com a HeroSpark você pode:',
      list: [
        'Criar landing pages de fácil captura',
        'Montar funis de vendas rapidamente',
        'Ter acesso a vários modelos prontos e validados',
        'Enviar e-mail marketing para seus futuros clientes',
      ],
    },
    whiteFrame: {
      title: 'Quer mais?',
      description: 'Publique seu infoproduto e faça checkout integrado com <strong>taxas de até 5,9%*.</strong>',
      showPlanDisclaimer: true,
    },
  },
  sparkfunnels: {
    intro: {
      name: 'Maria',
      description: 'Fala dois idiomas e ama ensinar idiomas na internet.',
    },
    transformation: {
      text: 'E-books e cursos online.',
    },
    features: {
      label: 'Com a HeroSpark ganhou:',
      list: [
        'Página de captura',
        'Funil de vendas',
        'Hospedagem de curso',
        'E-mail marketing',
        'Checkout',
        'Suporte/Comunidade',
      ],
    },
    whiteFrame: {
      title: 'Quais os benefícios?',
      description: `
        Começou <strong>grátis</strong> a vender de forma <strong>simples, rápida e segura</strong>.
        <br />
        Sem se preocupar com problemas de tecnologia e com um time heróico de suporte <strong>
        você também ganha tempo e pode se dedicar ao que ama fazer.</strong>
      `,
      showPlanDisclaimer: false,
    },
  },
  sparkmembers: {
    intro: {
      name: 'João Amadio',
      description: 'Tem talento para salvar vidas de pets e ensinar veterinária no digital.',
    },
    transformation: {
      text: 'Cursos online.',
    },
    features: {
      label: 'Com a HeroSpark ganhou:',
      list: [
        'Página de captura',
        'Funil de vendas',
        'Hospedagem de curso',
        'E-mail marketing',
        'Checkout',
        'Suporte/Comunidade',
      ],
    },
    whiteFrame: {
      title: 'Qual o resultado?',
      description: `
        Eu dava aulas para veterinários somente em Curitiba. Agora eu <strong>ensino pessoais em mais
        de 140 cidades e ganho mais de 40 mil todos os meses vendendo meus cursos online</strong>.
      `,
      showPlanDisclaimer: false,
    },
  },
};
