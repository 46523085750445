<template>
  <div class="steps-container">
    <div class="w-100 d-flex justify-content-end px-xs-0 py-3">
      <Stepper
        @jump="goTo"
        :steps="steps"
        :completed="completedSteps"
        :actual="actual"
        class="col-xs-12 col-sm-8 px-0"
      />
    </div>
    <div class="col-xs-12">
      <transition :name="transition" mode="out-in">
        <QuestionWrapper
          v-if="actual !== questions.length + 1"
          :key="`question${actual}`"
          :question="questions[actual - 1]"
          :validate="validateQuestion()"
          :actual-step="actual"
          :singular="singular"
          :multiselect="isMultiselect"
          @selected="onOptionSelected"
          @nextStep="onGoNext"
          @back="back"
        />
        <QuestionaryFinished
          @finished="finished"
          v-if="actual === questions.length + 1"
          :key="`question${questions.length + 1}`"
          @back="back"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Stepper from '@/components/Stepper/index';
import QuestionWrapper from './QuestionWrapper.vue';
import QuestionaryFinished from './QuestionaryFinished.vue';
import questionaryService from '@/services/questionary';

export default {
  props: {
    steps: Array,
    singular: Boolean,
    actual: Number,
    questions: Array,
  },
  data() {
    return {
      transition: 'slide-left-right-fade',
      wasFinished: false,
    };
  },
  components: { Stepper, QuestionWrapper, QuestionaryFinished },
  methods: {
    back() {
      this.goTo(this.actual - 1, 'back');
    },
    onOptionSelected(payload) {
      const showNextButton = this.showNextButton && (this.isMultiselect || payload.option.type === 'input');
      this.$emit('selected', {
        ...payload,
        isMultiselect: this.isMultiselect,
        showNextButton: showNextButton,
      });
    },
    validateQuestion() {
      const question = this.questions[this.actual - 1];
      const questionWithOptionSelected = !question.multiselect && question.selectedOption;
      const questionMultiselectWithAtLeastOneMarked = question.multiselect && question.options.some(q => q.selected);
      const questionWithSubquestion = Boolean(question.subquestions);

      return Boolean(questionWithOptionSelected || questionMultiselectWithAtLeastOneMarked || questionWithSubquestion);
    },
    goTo(step, direction = 'next') {
      if (this.actual > this.questions.length) {
        this.wasFinished = true;
      }
      this.$emit('step', step, direction);
    },
    onGoNext() {
      if (this.actual == 1 && this.questions[0].selectedOption == 'affiliate') {
        this.goTo(6);
      } else {
        this.goTo(this.actual + 1);
      }
    },
    finished() {
      this.goTo(7);
    },
  },
  watch: {
    actual(newValue, oldValue) {
      if (newValue < oldValue) {
        this.transition = 'slide-right-left-fade';
      } else {
        this.transition = 'slide-left-right-fade';
      }
    },
  },
  computed: {
    isMultiselect() {
      if (this.actual <= this.questions.length) {
        return this.questions[this.actual - 1].multiselect;
      }
      return false;
    },
    showNextButton() {
      if (this.actual <= this.questions.length) {
        return this.isMultiselect || this.questions[this.actual - 1].showNextButton;
      }
      return false;
    },
    hasOptionsSelected() {
      const optionsSelected = this.questions[this.actual - 1].options.filter(opt => opt.selected);

      return !!optionsSelected.length;
    },
    hasInputOptionFilled() {
      const optionsSelected = this.questions[this.actual - 1].options.filter(opt => opt.selected);
      const inputOption = optionsSelected.find(opt => opt.type === 'input');
      if (inputOption) {
        if (!inputOption.inputValue) {
          return false;
        }
      }
      return true;
    },
    completedSteps() {
      const normalized = questionaryService.normalizeQuestions(this.questions);
      const stepsCompleted = this.questions
        .map((question, index) => ({
          id: index + 1,
          question,
        }))
        .filter(({ question }) => {
          const selected = normalized[question.value];
          const questionWithSubquestionAnswer =
            question.subquestions && question.subquestions.some(subquestion => subquestion.value);
          return (
            questionWithSubquestionAnswer || (selected && ((!Array.isArray(selected) && selected) || selected.length))
          );
        });

      return stepsCompleted.filter(({ question }, index) => {
        const isCompletedOrActual = this.actual <= index + 1;
        const isLastCompleted = index === stepsCompleted.length - 1;
        const isLastQuestion = index + 1 === this.questions.length;
        if (question.multiselect && isLastCompleted && isCompletedOrActual && !(this.wasFinished && isLastQuestion)) {
          return false;
        }
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 580px) {
  .steps-container {
    width: 90%;
    justify-content: center;
    position: absolute;
    top: 40px;
    padding-top: 36px;
    background: red;
  }
}

.slide-left-right-fade-enter-active,
.slide-right-left-fade-enter-active {
  transition: all 0.25s ease;
}

.slide-left-right-fade-leave-active,
.slide-right-left-fade-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-left-right-fade-enter,
.slide-right-left-fade-leave-to {
  transform: translateX(30px);
  opacity: 0;
}
.slide-left-right-fade-leave-to,
.slide-right-left-fade-enter {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
