<template>
  <div class="d-flex justify-content-center align-items-center" :class="{ 'w-100': isLoading }">
    <hs-loading v-if="isLoading" />
    <template v-else>
      <hs-form @submit="update">
        <h5 class="auth-title" slot="title">
          {{ $t(`sign_up.${product}.form_name.title`) }}
        </h5>
        <p class="auth-subtitle">
          {{ $t(`sign_up.${product}.form_name.subtitle`) }}
        </p>
        <b-form-group>
          <label>{{ $t(`sign_up.${product}.form_name.fields.name.label`) }}</label>
          <hs-input
            id="name"
            :placeholder="$t(`sign_up.${product}.form_name.fields.name.placeholder`)"
            v-model="name"
            maxlength="35"
            @blur="
              $v.name.$touch();
              shouldNameChangeSubdomain = false;
            "
            :state="!$v.name.$error ? null : false"
          >
            <template slot="feedback" v-if="$v.name.$error">
              <hs-form-invalid-feedback :state="false">
                <span v-if="!$v.name.required">
                  {{ $t(`sign_up.${product}.form_name.fields.name.validations.required`) }}
                </span>
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </b-form-group>

        <b-form-group>
          <label for="subdomain">{{ $t(`sign_up.${product}.form_name.fields.subdomain.label`) }}</label>
          <div id="subdomain-wrapper" class="d-flex">
            <div class="flex-grow-1">
              <hs-input
                id="subdomain"
                maxlength="35"
                :placeholder="$t(`sign_up.${product}.form_name.fields.subdomain.placeholder`)"
                v-model="subdomain"
                @blur="$v.subdomain.$touch()"
                :state="!$v.subdomain.$error ? null : false"
              >
                <template slot="feedback" v-if="$v.subdomain.$error || subdomainError">
                  <hs-form-invalid-feedback :state="false">
                    <span v-if="!$v.subdomain.required">
                      {{ $t(`sign_up.${product}.form_name.fields.subdomain.validations.required`) }}
                    </span>
                    <span v-if="subdomainError">
                      {{ $t(`sign_up.${product}.form_name.fields.subdomain.errors.${subdomainError}`) }}
                    </span>
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </div>
            <div id="subdomain-suffix" class="d-flex justify-content-center align-items-center">{{ suffix }}</div>
          </div>
        </b-form-group>

        <ul>
          <li v-for="key in Object.keys(this.$t(`sign_up.${product}.form_name.recomendations`))" :key="key">
            {{ $t(`sign_up.${product}.form_name.recomendations.${key}`) }}
          </li>
        </ul>
        <hs-button size="lg" class="my-4" variant="purple" block type="submit" :disabled="$v.$invalid">{{
          $t(`sign_up.${product}.form_name.buttons.finish`)
        }}</hs-button>
      </hs-form>
    </template>
  </div>
</template>

<script>
import { hsForm, hsLoading } from '@/components';
import { required, maxLength } from 'vuelidate/lib/validators';
import ToastHelper from '@/shared/helpers/toast';
import GeneralHelper from '@/shared/helpers/general';
import { subdomainService } from '@/services';
import Tapfiliate from '@/shared/mixins/Tapfiliate';

export default {
  mixins: [Tapfiliate],
  props: {
    product: {
      type: String,
      required: true,
    },
  },
  components: {
    hsForm,
    hsLoading,
  },
  computed: {
    suffix: () => process.env.VUE_APP_HERO_DOMAIN,
  },
  data() {
    return {
      isLoading: false,
      name: this.$store.state.school.newSchool.name,
      subdomain: '',
      shouldNameChangeSubdomain: true,
      subdomainError: undefined,
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(35),
    },
    subdomain: {
      required,
      maxLength: maxLength(35),
    },
  },
  watch: {
    name: function(value) {
      if (this.shouldNameChangeSubdomain) {
        this.subdomain = GeneralHelper.slug(value);
      }
    },
    subdomain: function(value) {
      this.subdomain = GeneralHelper.slug(value);
    },
  },
  methods: {
    validateSubdomain(subdomain) {
      return subdomainService.subdomainValidation(subdomain);
    },
    async update() {
      try {
        this.isLoading = true;
        await this.validateSubdomain(this.subdomain);
        this.resetForm();
        this.$emit('proceed');
      } catch (e) {
        const { response } = e;
        ToastHelper.dangerMessage(this.$t(response.data.error));
        this.subdomainError = response.status;
      } finally {
        this.isLoading = false;
      }
    },

    resetForm() {
      const { name, subdomain } = this.$options.data.call(this);
      this.name = name;
      this.subdomain = subdomain;
      this.$v.$reset();
    },
    generateSubdomain() {
      const rand = () => Math.floor(Math.random() * 10);
      const convertedName = this.name
        .replace(/\s+/g, '-')
        .replace(/[^a-zA-Z-]/g, '')
        .toLowerCase();

      return `${convertedName}${rand()}${rand()}${rand()}`;
    },
  },
  mounted() {
    this.subdomain = this.generateSubdomain();
  },
};
</script>

<style lang="scss" scoped>
ul {
  font-size: 12px;
  line-height: 18px;
  color: $grey-30;
  padding-left: 18px;
  list-style: none;
  margin-top: 32px;

  & li::before {
    content: '\2022';
    color: $grey-25;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

#subdomain-suffix {
  background: $grey-10;
  border-color: $grey-22;
  border-style: solid;
  border-width: 1px 1px 1px 0;

  height: fit-content;
  padding: 6px;

  font-size: 16px;
  line-height: 150%;
  color: $grey-25;

  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

#subdomain-wrapper {
  /deep/.hs-input {
    input {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>
