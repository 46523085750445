export default {
  methods: {
    tapFiliateCookieNotPresent() {
      return document.cookie.indexOf('tap_vid') < 0;
    },
    tapFiliateReferrerQueryString() {
      return this.$route.query.ref;
    },
    tapFiliateIsPresent() {
      return this.tapFiliateReferrerQueryString() !== undefined;
    },
    tapFiliateReferrerCode() {
      return this.tapFiliateIsPresent() ? this.tapFiliateReferrerQueryString().replace('#', '') : '';
    },
  },
};
