import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';

const cnpjAndCpfIsValid = document_number => {
  const number = document_number.replace(/\D/g, '');

  if (number.length == 14) {
    return isValidCNPJ(document_number);
  }
  if (number.length == 11) {
    return isValidCPF(document_number);
  }
  return false;
};

export default cnpjAndCpfIsValid;
