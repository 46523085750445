<template>
  <hs-group :label="$t(`sign_up.sparkmembers.form.fields.document`)" label-for="signUp_document">
    <div class="d-flex">
      <hs-multiselect
        id="document_type"
        class="w-25 mr-2"
        v-model="documentType"
        track-by="value"
        label="text"
        :options="documentTypes"
        :searchable="false"
        :allow-empty="false"
        :show-labels="false"
      />
      <div class="d-flex flex-column w-75">
        <b-input
          id="signUp_document"
          v-mask="setMask"
          :placeholder="setDocumentPlaceholder"
          v-model="document"
          @blur.native="$v.document.$touch()"
          :state="documentState"
          @input="onDocumentInput"
        >
        </b-input>
        <hs-form-invalid-feedback v-if="!$v.document.required" :state="!$v.document.required">
          <hs-icon size="13" icon="exclamation-circle" class="mr-1" />
          <span class="font-size-xs">
            {{ $t(`sign_up.sparkmembers.form.validation.required.document`) }}
          </span>
        </hs-form-invalid-feedback>
        <div v-else>
          <hs-form-invalid-feedback v-if="$v.document.$invalid && $v.document.$dirty" :state="!$v.document.$invalid">
            <hs-icon size="13" icon="exclamation-circle" class="mr-1" />
            <span class="font-size-xs">
              {{ $t(`sign_up.sparkmembers.form.validation.required.invalid_document`) }}
            </span>
          </hs-form-invalid-feedback>
        </div>
      </div>
    </div>
  </hs-group>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import cnpjAndCpfIsValid from '@/shared/helpers/CustomValidations/cnpjAndCpfIsValid';

export default {
  name: 'DocumentChoose',
  directives: { mask },
  validations: {
    document: {
      required,
      cnpjAndCpfIsValid,
    },
  },
  data() {
    return {
      document: '',
      documentTypes: [{ value: 'CPF', text: 'CPF' }, { value: 'CNPJ', text: 'CNPJ' }],
      documentType: { value: 'CPF', text: 'CPF' },
    };
  },
  computed: {
    setDocumentPlaceholder() {
      return this.documentType.value === 'CPF'
        ? this.$t(`sign_up.sparkmembers.form.fields.placeholder_cpf`)
        : this.$t(`sign_up.sparkmembers.form.fields.placeholder_cnpj`);
    },
    setMask() {
      return this.documentType.value === 'CPF' ? '###.###.###-##' : '##.###.###/####-##';
    },
    documentState() {
      return !this.$v.document.$error || !this.$v.document.$invalid ? null : false;
    },
  },
  methods: {
    onDocumentInput() {
      this.$emit('documentChoosen', {
        is_valid: !this.$v.document.$invalid,
        document: this.document,
        document_type: this.documentType.value,
      });
    },
  },
  watch: {
    documentType() {
      this.onDocumentInput();
    },
    document() {
      this.onDocumentInput();
    },
  },
};
</script>

<style lang="scss" scoped></style>
