<template>
  <VueRecaptcha
    ref="invisibleRecaptcha"
    size="invisible"
    :sitekey="sitekey"
    @verify="onVerify"
    @expired="onExpired"
    @error="onError"
  />
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import ToastHelper from '@/shared/helpers/toast';

export default {
  data() {
    return {
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    };
  },
  components: {
    VueRecaptcha,
  },
  methods: {
    execute() {
      this.$refs.invisibleRecaptcha.execute();
    },

    onVerify(response) {
      this.$emit('verify', response);
    },

    onExpired() {
      console.log('Captcha expired'); // eslint-disable-line no-console
    },

    onError() {
      this.$emit('error');
      console.log('Captcha error'); // eslint-disable-line no-console
      ToastHelper.dangerMessage(this.$t('captcha.toast.error'));
    },
  },
};
</script>
